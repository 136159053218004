import React, { useRef } from "react";
import { graphql } from "gatsby";
import Layout from "@/components/Layout";
import { CustomRenderer } from "@/utils/CustomRenderer";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface Props {
  data: {
    cms: {
      page: {
        title: String;
        content: any;
      };
    };
  };
  pageContext: any;
}
const ContactUs = ({ data, pageContext }: Props) => {
  const { menus } = pageContext;
  const { content, title } = data?.cms.page;
  const InputStyling = `border-b-[1px] mb-[1rem] border-gray p-[1rem]
  outline-none duration-300 ease-in-out focus:border-black bg-lightGray`;
  const form = useRef();
  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_d4j8lis",
        "template_3xlndmk",
        form.current,
        "h66u8_H-_v70U6zxF"
      )
      .then(
        (result) => {
          e.target.reset();
          toast.success("Your request was successfully submitted");
        },
        (error) => {
          toast.error("Something went wrong with submission");
        }
      );
  };

  return (
    <Layout title={title} pageContext={menus}>
      <div className="container pb-[2rem]">
        <div className="lg:grid lg:grid-cols-2 px-[1rem] md:px-[5rem] lg:px-[10rem] lg:gap-[6rem]">
          <form
            ref={form}
            action="/contact-us"
            method="post"
            name="contact"
            onSubmit={sendEmail}
            data-netlify={true}
            className=" mb-[4rem] flex flex-col lg:mb-0"
          >
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                className={InputStyling}
              />
            </div>
            <div>
              <input
                type="text"
                name="email"
                placeholder="Email"
                className={InputStyling}
              />
            </div>
            <div>
              <input
                type="text"
                name="telephone"
                placeholder="Telephone"
                className={InputStyling}
              />
            </div>
            <div>
              <textarea
                name="message"
                cols={30}
                rows={10}
                placeholder="Quick detail"
                className="border-[1px] mb-[1rem] bg-lightGray border-gray p-[1rem] outline-none duration-300 ease-in-out focus:border-black w-full"
              ></textarea>
            </div>
            <div>
              <input
                type="submit"
                value="Send message"
                className="max-w-max bg-black py-[0.8rem] px-[2rem] font-[600] duration-300 ease-in-out hover:bg-theme hover:text-black text-white "
              />
            </div>
          </form>
          <div>
            <CustomRenderer document={content.document} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query ContentById($id: ID) {
    cms {
      page(where: { id: $id }) {
        title
        id
        content {
          document(hydrateRelationships: true)
        }
      }
    }
  }
`;
export default ContactUs;
